<template>
  <div v-if="mostrarSpinner" 
          class="row justify-content-md-center">
          <div class="col-3 alert-info text-center mt-5">
              Espere por favor...
              <h3 class="mt-2">
                  <i class="fa fa-spin fa-sync"></i>
              </h3>
          </div>
        </div>
</template>

<script>
export default {

    props: {
        mostrarSpinner: {
            type: Boolean
        }
    }

}
</script>

<style>

</style>